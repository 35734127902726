import { BigNumber, ethers } from 'ethers';
import { nftContractAddress } from '../Config/Config';
import React, { useState } from 'react';
import { createContext } from 'react';
import Swal from 'sweetalert2';


const contractABI = require("../Abi/abi.json");

const ChainContext = createContext({})

const BlockchainContext = ({ children }) => {

    const [wallet, setWallet] = useState([]);
    let contract



    const showAlerts = (alert, message, title = '') => {
        switch (alert) {
            case "success":
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: title,
                    text: message,
                    showConfirmButton: false,
                    timer: 1500,
                    background: "#0b1225"
                })
                break;
            case "error":
                Swal.fire({
                    icon: 'error',
                    toast: true,
                    title: title,
                    text: message,
                    background: "#0b1225"
                })
                break;
            case "warning":
                Swal.fire({
                    icon: 'warning',
                    title: title,
                    text: message,
                    background: "#0b1225"
                })
                break;
            default:
        }
    }



    const connectWallet = async () => {

        if (!sessionStorage.getItem("walletConnected")) {
            sessionStorage.setItem("walletConnected", false)
        }

            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts"
                });
                setWallet(accounts);
                sessionStorage.setItem("walletConnected", true)
                showAlerts('success', "Connection Successful", "Connected")

            } catch (err) {
                showAlerts('error', "Metamask connection failed " + err.message, "Connection Fail")
                sessionStorage.setItem("walletConnected", false)
                return;
            }

        }
    


    const getConnect = async () => {

            const provider = new ethers.providers.Web3Provider(window.ethereum);

            const signer = provider.getSigner();

            contract = new ethers.Contract(
                nftContractAddress,
                contractABI,
                signer
            );
        }


    return (


        <ChainContext.Provider value={{ connectWallet, wallet, getConnect}}>
            {children}
        </ChainContext.Provider>

    )




};

export { BlockchainContext, ChainContext };