import React from 'react';
import tw from '../../Assets/twitter.png'
import open from '../../Assets/opensea.png'
import etherscan from "../../Assets/etherscan.png"

const Footer = () => {
    return (
        <div className='bg-green min-h-[10vh]'>
            <div className="footerWrapper px-10 max-w-[1400px] mx-auto mobile:overflow-hidden ">

                <footer className="footer footer-center p-10 bg-transparent ">

                    {/* <img src={logo} className='w-10' alt="logo" /> */}
                   
                    <div>
                        <p className='text-white'>Copyright © {new Date().getFullYear()} -  Stoned Pepe.</p>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default Footer;