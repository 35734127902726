import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { BlockchainContext } from './Components/Context/BlockchainContext'
import '../src/Assets/Wantedo.ttf';

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
		<BrowserRouter>
				<BlockchainContext>
					<App />
				</BlockchainContext>
		</BrowserRouter>
)
