
import React, { Fragment, useState } from "react";
import './FAQ.css'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
  } from "@material-tailwind/react";

  function Icon({ id, open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`${
          id === open ? "rotate-180" : ""
        } h-5 w-5 text-gold ml-3 transition-transform`}
        fill="none"

        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
      </svg>
    );
  }

const FAQ = () => {

    const [open, setOpen] = useState(0);
   
    const handleOpen = (value) => {
      setOpen(open === value ? 0 : value);
    };

    return (
        <div className=" bg-black min-h-[100vh]"  >
            <div className="px-10 max-w-[960px] mx-auto desktop:pt-40 mobile:pt-16">
                <div className="content grid text-center laptop:grid-cols-1 desktop:grid-cols-1 mobile:grid-cols-1 mobile:overflow-hidden" >
                <div className="desktop:text-6xl laptop:text-6xl mobile:text-5xl text-center pt-3 pb-3 border-0 text-white font-bold" style={{borderRadius: "15px", "textAlign":"center"}}>FAQs</div>

                     <Fragment>
                          <Accordion
                            open={open === 1}
                            icon={<Icon id={1} open={open} />}
                            onClick={() => handleOpen(1)}
                          >
                            <AccordionHeader><p className="text-white font-light">How many Merge Dark Ape are there?</p></AccordionHeader>
                            <AccordionBody>
                            <p className="text-white" style={{left: "15px"}}>
                            10000
                              </p>
                            </AccordionBody>
                          </Accordion>
                          <Accordion
                            open={open === 2}
                            icon={<Icon id={2} open={open} />}
                            onClick={() => handleOpen(2)}
                          >
                            <AccordionHeader><p className="text-white font-bold">When is the Mint?</p></AccordionHeader>
                            <AccordionBody>
                            <p className="text-white">
                             Minting Now
                              </p>
                            </AccordionBody>
                          </Accordion>
                          <Accordion
                            open={open === 3}
                            icon={<Icon id={3} open={open} />}
                            onClick={() => handleOpen(3)}
                          >
                            <AccordionHeader><p className="text-white font-light">What kind of smart contract will you use?</p></AccordionHeader>
                            <AccordionBody>
                            <p className="text-white">
                            We will utilize an ERC-721A contract which gives us lower gas fees.
                              </p>
                            </AccordionBody>
                          </Accordion>
                        </Fragment>
                        
                    
                </div>
            </div>


        </div>
    );
};

export default FAQ;