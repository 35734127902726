import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../Components/Banner/Banner';
import FAQ from '../Components/FAQ/FAQ';
import Overview from '../Components/Overview/Overview';
import '../App.css'


const HomePage = () => {
    
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Mint | Stoned Pepe</title>
            </Helmet>
            <Banner />
           
       
          

        </div>
    );
};

export default HomePage;