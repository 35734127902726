
import React, { useEffect, useRef, useState } from 'react';
import tw from '../Assets/twitter.png'
import open from '../Assets/opensea.png'
import rari from '../Assets/rari.png'
import etherscan from "../Assets/etherscan.png"
import { Link } from 'react-router-dom';
import menu from '../Assets/menu.png';
import logo from "../Assets/logo2.png";
import twitter from "../Assets/twitter.png";


const Navbar = () => {

    const [themeMenuOpened, setThemeMenuOpened] = useState(true);
    const themeMenu = useRef(null);
    const themeMenuButton = useRef(null);
    const [stickyClass, setStickyClass] = useState('bg-green');


    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);

        return () => {
            window.removeEventListener('scroll', stickNavbar);
        };
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            windowHeight > 50 ? setStickyClass('sticky z-50 bg-green') : setStickyClass('bg-green');
        }
    };

    useEffect(() => {
        if (!themeMenuOpened) {
            document.activeElement.blur();
        } else if (
            themeMenuOpened &&
            !themeMenu.current.contains(document.activeElement)
        ) {
            setThemeMenuOpened(false);

        }
    }, [themeMenuOpened]);

    


    return (
        <div className='navbarWrapper max-w-[1600px] mx-auto '>
            <div className={`navbar ${stickyClass} laptop:pl-30 mx-auto mobile:p-1 desktop:px-40 `}>
                <div className="navbar-start">
                    <div ref={themeMenu} className="dropdown">
                        <div className="content flex flex-row items-center">
                            <label ref={themeMenuButton}
                                onBlur={(e) => {
                                    setThemeMenuOpened(false);
                                }}
                                onClick={(e) => {
                                    if (themeMenuOpened) {
                                        setThemeMenuOpened(false);
                                    } else {
                                        setThemeMenuOpened(true);
                                    }
                                }}
                                tabIndex="0" className="btn btn-ghost btn-circle">
                                <img className="w-8 ml-6 mr-4 pr-2" src={menu} alt="menu"></img>
                            </label>

                            <div className="laptop:inline-block desktop:inline-block mobile:hidden ml-5 ">
                                <Link to={'/'} className="laptop:text-xl desktop:text-xl mobile:text-xs hover:bg-transparent">
                                    <span><img src={logo} className='laptop:w-9 desktop:w-9 mobile:w-7 mr-2 inline-block h-10' alt="logo" srcSet="" style={{width:"40vh"}}/></span> 
                                </Link>
                            </div>

                            <div className=" laptop:hidden desktop:hidden mobile:inline-block ml-3">
                                <Link to={'/'} className="">
                                    <img src={logo} className='laptop:w-9 desktop:w-9 mobile:w-7 ml-3 inline-block' alt="logo" srcSet="" />
                                </Link>
                            </div>

                        </div>

                        <ul
                            onBlur={(e) => {
                                themeMenuButton.current.focus();
                            }}
                            onFocus={(e) => {
                                setThemeMenuOpened(true);
                            }}
                            tabIndex="0" className="menu menu-vertical dropdown-content text-white mt-3 p-2 shadow bg-deepDarkBg rounded-box w-52">

                            <li className='hover:text-primaryBg'>
                                <a href='/' className="link link-hover hover:text-white">Home</a></li>

                                          
                        </ul>
                    </div>
                </div>

                <div className="navbar-end m-0 desktop:p-0 laptop:pr-8 mobile:pr-10">

                    <div className="grid grid-flow-col gap-4 text-white hover:cursor-pointer items-center">
                  
                       <a href='https://raritysniper.com/nft-drops-calendar' target="_blank" rel="noopener noreferrer">
                            <img src={rari} className='laptop:w-8 desktop:w-8 mobile:w-6' alt="etherscan" title='etherscan' />
                        </a>
                        <a href='https://opensea.io/collection/stoned-pepe' target="_blank" rel="noopener noreferrer">

                            <img src={open} className='laptop:w-7 desktop:w-7 mobile:w-6' alt="opensea" title='opensea' />
                        </a>
                        <a href='https://etherscan.io/address/0x78319dcc9af63333cab5c5dec4b667f6d46a2358' target="_blank" rel="noopener noreferrer">
                            <img src={etherscan} className='laptop:w-8 desktop:w-8 mobile:w-6' alt="etherscan" title='etherscan' />
                        </a>
  <a href='https://twitter.com/stonedpepenft' target="_blank" rel="noopener noreferrer">
                            <img src={twitter} className='laptop:w-8 desktop:w-8 mobile:w-6' alt="etherscan" title='etherscan' />
                        </a>
                       

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;