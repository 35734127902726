import React, { useState, useEffect, useRef } from 'react';
import { BigNumber, ethers } from 'ethers';
import './Banner.css'
import Navbar from '../Navbar';
import background from '../../Assets/bg.png'
import { nftContractAddress } from '../Config/Config';
import Swal from 'sweetalert2';
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import {
  chain,
  configureChains,
  createClient,
  WagmiConfig,
  useAccount
} from 'wagmi';
import { infuraProvider } from 'wagmi/providers/infura';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { publicProvider } from 'wagmi/providers/public';
import nft1 from "../../Assets/1.jpeg";
import nft2 from "../../Assets/2.jpeg";
import nft3 from "../../Assets/3.jpeg";
import nft4 from "../../Assets/4.jpeg";
import nft5 from "../../Assets/5.jpeg";
import plus from "../../Assets/plus.png"
import minus from "../../Assets/minus.png"

const contractABI = require("../Abi/abi.json");

const infuraId = process.env.INFURA_ID;

    const { chains, provider } = configureChains(
    [chain.mainnet],
    [infuraProvider({ infuraId }),publicProvider(),]
)
const { connectors } = getDefaultWallets({
    appName: 'My RainbowKit App',
    chains
  });
  
  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
  })

export const YourApp = () => {
    return (
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          mounted,
        }) => {
          return (
            <div
              {...(!mounted && {
                'aria-hidden': true,
                'style': {
                  opacity: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            >
              {(() => {
                if (!mounted || !account || !chain) {
                  return (
                    
                    <button className=' text-center text-xl text-white border-2 border-maroon hover:border-gold bg-maroon hover:text-deepDarkBg desktop:pl-3 desktop:pt-2 desktop:pr-3 desktop:pb-2
                    mobile:pl-2 mobile:pr-2 mobile:pb-1 mobile:pt-1 border-1 border-red 
                    ' onClick={openConnectModal} style={{borderRadius: "15px", margin:"0 auto", display:"block"}}>CONNECT</button>
                    
                  );
                }
  
                if (chain.unsupported) {
                  return (
                    <button onClick={openChainModal} type="button">
                      Wrong network
                    </button>
                  );
                }
  
                return (
                  <div className='desktop:w-6 laptop:w-6 mobile:w-4' style={{ display: 'flex', gap: 5 }}>
                    <button
                      onClick={openAccountModal}
                      style={{ display: 'flex', alignItems: 'center' }}
                      type="button"
                    >
                      {chain.hasIcon && (
                        <div
                          style={{
                            background: chain.iconBackground,
                            width: 25,
                            height: 25,
                            borderRadius: 999,
                            overflow: 'hidden',
                            marginRight: 4,
                          }}
                        >
                          {chain.iconUrl && (
                            <img
                              alt={chain.name ?? 'Chain icon'}
                              src={chain.iconUrl}
                              style={{ width: 25, height: 25 }}
                            />
                          )}
                        </div>
                      )}
                      {/* {chain.name} */}
                    </button>
  
                    <button onClick={openAccountModal} type="button" className="text-xs font-light" style={{fontFamily: "Barlow"}}/>
                  </div>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    );
  };

  const featuredProducts = [
    nft1,
    nft2,
    nft3,
    nft4,
    nft5
  ];
  
  let Count = 0;
  let slideInterval;

  export function Modal() {

    const [count, setCount] = useState(1);
    const [currentSupply, setCurrentSupply] = useState(null);
    const [connected, setConnected] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [maxpertx, setMaxPerTx] = useState(null);
    const [MaxSupply, setMaxSupply] = useState(null);
    const [price, setPrice] = useState(null);
    const [address, setAddress] = useState(null)
   
    const infuraId = process.env.INFURA_ID;

    const slideRef = useRef();

    const removeAnimation = () => {
      slideRef.current.classList.remove("fade-anim");
    };

  useEffect(() => {
    slideRef.current.addEventListener("animationend", removeAnimation);
    slideRef.current.addEventListener("mouseenter", pauseSlider);
    slideRef.current.addEventListener("mouseleave", startSlider);

    startSlider();
    return () => {
      pauseSlider();
    };
    // eslint-disable-next-line
  }, []);

    function Profile() {
    const {account, isConnected} = useAccount({
      onConnect({ address }) {
      setConnected(true)
      setAddress(address);
      if(window.ethereum){
        async function load(){
        let provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      let contract = new ethers.Contract(
          nftContractAddress,
          contractABI,
          signer
      );
    const user = signer.getAddress();
    const costContract = (await contract.PUBLIC_SALE_PRICE()).toString();
    const cost = count * costContract;
    const cc = (cost.toString());
    const price = ethers.utils.formatEther(cc)
    const priceformatted = Number(price).toFixed(4);
    console.log(priceformatted)
    const balanceString = await provider.getBalance(signer.getAddress())
    const balance = Number(balanceString)
    const freeString = (await contract.MAX_FREE_PER_WALLET()).toString();
    const free = Number(freeString)
    const mintedAlreadyString = (await contract.balanceOf(user)).toString();
    const mintedAlready = Number(mintedAlreadyString)

    if(mintedAlready < free){
      setPrice(0)
    }
    else{
      setPrice(priceformatted)
    }
      }
      load();
    }
  }
  }
)}

  const startSlider = () => {
    slideInterval = setInterval(() => {
      handleOnNextClick();
    }, 1000);
  };

  const pauseSlider = () => {
    clearInterval(slideInterval);
  };

  const handleOnNextClick = () => {
    Count = (Count + 1) % featuredProducts.length;
    setCurrentIndex(Count);
    slideRef.current.classList.add("fade-anim");
  };

    const showAlerts = (alert, message, title = '') => {
        switch (alert) {
            case "success":
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: title,
                    text: message,
                    showConfirmButton: false,
                    timer: 1500,
                    background: "#0b1225"
                })
                break;
            case "error":
                Swal.fire({
                    icon: 'error',
                    toast: true,
                    title: title,
                    text: message,
                    background: "#0b1225"
                })
                break;
            case "warning":
                Swal.fire({
                    icon: 'warning',
                    title: title,
                    text: message,
                    background: "#0b1225"
                })
                break;
            default:
        }
    }


    const handleMint = async() => {
      if(window.ethereum){
      let provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          let contract = new ethers.Contract(
              nftContractAddress,
              contractABI,
              signer
          );
      const user = signer.getAddress();
      const costContract = (await contract.PUBLIC_SALE_PRICE()).toString();
      const cost = count * costContract;
      const cc = (cost.toString());
      const balanceString = await provider.getBalance(signer.getAddress())
      const balance = Number(balanceString)
      const freeString = (await contract.MAX_FREE_PER_WALLET()).toString();
      const free = Number(freeString)
      const mintedAlreadyString = (await contract.balanceOf(user)).toString();
      const mintedAlready = Number(mintedAlreadyString)
      console.log(mintedAlready);
      console.log(balance)
      
      if(mintedAlready < free && count == free){
       if(balance > 0){
          try {
          await contract.mint((count), {
              value: 0
            });
            
          } catch (error) {
              if(error.message == 'MetaMask Tx Signature: User denied transaction signature.')
                showAlerts('error','Fail',error.message)
                else{
                  showAlerts('error','Fail', "Not enough balance")
                }
          }}else{
            showAlerts('error','Fail', "Not enough balance")
        }
      }else{
          const costContract = (await contract.PUBLIC_SALE_PRICE()).toString();
          const cost = count * costContract;
          const cc = (cost.toString());
          const ccNumber = Number(cc)
          const balanceString = await provider.getBalance(signer.getAddress())
          const balance = Number(balanceString)
          console.log(cc)
          const canMint = Math.floor(balance/costContract);

          try {
          await contract.mint((count), {
              value: BigNumber.from(cc)
            });
          } catch (error) {
              if(error.message == 'MetaMask Tx Signature: User denied transaction signature.')
                showAlerts('error','Fail',error.message)
                else{
                  showAlerts('error','Fail', `Not enough balance. You can only mint ${canMint} NFTs`)
                }
          }
        }
      }else{
        window.open("https://metamask.app.link/dapp/mint.nftname.com") //Do not add https from your domain here
      }
      
     
  }


    useEffect(() => {
        async function load() {
            let provider = new ethers.providers.InfuraProvider("homestead" , infuraId);
            let contract = new ethers.Contract(
            nftContractAddress,
            contractABI,
            provider
            );
            const max = (await contract.maxSupply()).toString();
            setMaxSupply(max)
            const supply = await contract.totalSupply();
            console.log(supply.toString());
            setCurrentSupply(supply.toString());
            
            const maxpertx = (await contract.MAX_MINTS_PER_TX()).toString();
            console.log(Number(maxpertx))
            setMaxPerTx(Number(maxpertx))
            
        }
        load();
    },[])

    const handleAdd = async() => {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      let contract = new ethers.Contract(
          nftContractAddress,
          contractABI,
          signer
      );
    const user = signer.getAddress();
    const costContract = (await contract.PUBLIC_SALE_PRICE()).toString();
    const cost = count * costContract;
    const cc = (cost.toString());
    const price = ethers.utils.formatEther(cc)
    const priceformatted = Number(price).toFixed(4);
    console.log(priceformatted)
    const freeString = (await contract.MAX_FREE_PER_WALLET()).toString();
    const free = Number(freeString)
    const mintedAlreadyString = (await contract.balanceOf(user)).toString();
    const mintedAlready = Number(mintedAlreadyString)

      if(count<maxpertx && count>=1) {
      setCount((count + 1))
      if(mintedAlready < free && count < free) {
        setPrice(0)
      }
      else{
        setPrice(0.001)
      }
      }
      
    }

    const handleSub = async() => {   
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      let contract = new ethers.Contract(
          nftContractAddress,
          contractABI,
          signer
      );
    const user = signer.getAddress();
    const costContract = (await contract.PUBLIC_SALE_PRICE()).toString();
    const cost = count * costContract;
    const cc = (cost.toString());
    const price = ethers.utils.formatEther(cc)
    const priceformatted = Number(price).toFixed(4);
    console.log(priceformatted)
    const freeString = (await contract.MAX_FREE_PER_WALLET()).toString();
    const free = Number(freeString)
    const mintedAlreadyString = (await contract.balanceOf(user)).toString();
    const mintedAlready = Number(mintedAlreadyString) 
      if(count>1){
      setCount(count - 1)
      }
      if(mintedAlready < free && count < 4) {
        setPrice(0)
      }
      else{
        setPrice(0.001)
      }
 
    }

    return(
      <>
                    <div className="bg-[url('/src/Assets/jail2.png')] border-0 desktop:mt-0 laptop:mt-0 mobile:mt-32" style={{borderRadius:"15px"}} data-aos="fade-down">

                        
                        <div className='glasseffect p-10' style={{fontSize: "20px"}}>
                       
                     
                    <div ref={slideRef} className="select-none relative mx-auto my-auto mb-4">
                      <div>
                        <img className='border-1 border-green' src={featuredProducts[currentIndex]} alt="NFT" style={{borderRadius: "15px", width: "40vh"}}/>
                      </div>
                      
                
                    </div>

                    <div className='text-center'>
                    
                  
                        <button type="button" ><img className=" w-12" onClick={handleSub} src={minus} alt="minus" /></button>
                        
                        <span className="font-bold text-gold text-2xl ml-6 mb-3">{count}</span>
                        
                        <button type="button"><img className="w-12" onClick={handleAdd} src={plus} alt="plus" /></button>
                   
                    </div>
                    {connected ? (<div className="desktop:-ml-1 mobile:ml-7 text-l text-center mt-3">
                    <span><span className="text-gold">Total: </span>{Number(price*count).toFixed(4)} ETH</span>
                    </div>) : (<div className="font-light text-center text-l mt-3">
                    <span className="text-gold ">Total: </span><span>1 st free<br/>then 0.001 ETH each</span>
                    </div>)}
                    

                    <div className="mt-2" style={{fontSize: "15px"}}>
                        {connected ? (<div><button type="button" 
                            className="text-center text-2xl text-white border-2 border-red rounded-lg bg-maroon bg-green hover:text-deepDarkBg mb-2 mt-3 px-3 py-2"
                            style={{margin:"0 auto", display:"block"}}
                            onClick={handleMint}
                           
                            >MINT NOW</button>
                     <div className="mt-2 text-center" style={{fontSize: "15px"}}>
                     <span className="text-white">Total Minted: {currentSupply} / {MaxSupply}</span>
                     </div>       
                            
                            
                            </div>) : (
                        <div className='mt-8'>
                        <WagmiConfig client={wagmiClient}>
                        <RainbowKitProvider chains={chains} >
                        <YourApp/>
                        <Profile/>
                        </RainbowKitProvider>
                        </WagmiConfig>
                        <div className="mt-2 text-center" style={{fontSize: "15px"}}>
                     <span>Total Minted: {currentSupply} / {MaxSupply}</span>
                     </div>
                        </div>
                        )

                        }
                             
                        </div>
                        
                        </div>
                        
                        
                      
                    </div>
                    </>
    );
  }


const Banner = () => {
  const [showModal, setShowModal] = React.useState(false);

    return (
        
        <div className='bannar-wrapper text-white' id='Mint'>
            <Navbar />

            <img className='bannerVideo bg-cover' src={background} alt="banner"></img>
            

            <div className="hero min-h-[10vh] max-h-auto max-w-[800px] mx-auto">
            <div className="hero-content">
                
                 
                  {showModal ? (<>
                    <Modal />
                    <button
                    className="mx-auto text-red-500 background-transparent uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                    </>
                     ) : (
                      <div className="desktop:ml-0 laptop:ml-0 mobile:ml-0 desktop:mt-5 laptop:mt-5 mobile:mt-0">

                      <div className="mobile:mb-12">
                      <button
                className="ml-6 text-white bg-maroon active:bg-gold bg-green uppercase text-2xl px-6 py-2 shadow hover:shadow-lg outline-none focus:outline-none mr-1 desktop:mb-80 mobile:mb-96 ease-linear transition-all duration-150 border-3 border-gold" 
                  style={{borderRadius: "15px", display: "block", margin: "0 auto"}}
                  type="button"
                  onClick={() => setShowModal(true)}>
                    MINT
                  </button>
                  </div>
                  </div>)}
                </div>
            </div>
            </div>
        
    );
};

export default Banner;